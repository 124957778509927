import { Coupe } from './Parts/Coupe'
import { PickUp } from './Parts/PickUp'
import { Sedan } from './Parts/Sedan'
import { HacthBack } from './Parts/HatchBack'
import { Camioneta } from './Parts/Camioneta'
import { Convertible } from './Parts/Convertible'

/* export const models = [

    Sedan,
    HacthBack,
    PickUp,
    Camioneta,
    Coupe,
    Convertible


] */
export const models = [

    Sedan,
    HacthBack,
    PickUp,
    Coupe

]
